
import Storage from "@/util/Storage"
import {findMyFriend, myFriendAbout} from "@/Api/Friend";
import {MyFriendStore} from "@/views/Friend/MyFriend/Store";

/** 获取自己的朋友圈 */
export function getMyFriends() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( findMyFriend({ userId,token },MyFriendStore.getMyFriendsUpData).then(res=>{
        return res.data
    }) )
}


/** 获取关于我的朋友圈 */
export function getMyAbouts() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( myFriendAbout({ userId,token }).then(res=>{
        return res.data
    }) )
}
