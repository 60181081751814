

import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store/index"
import MyFriendStoreType, {upDataType} from "@/views/Friend/MyFriend/Store/indexType";
import {ItemType} from "@/views/Friend/FriendCircle/Store/indexType";
import {MyAboutItemType} from "@/views/Friend/MyFriend/MyFriend";

@Module({
    name:"MyFriend",
    store,
    dynamic: true,
})
export default class MyFriend extends VuexModule implements MyFriendStoreType{

    myFriendsList: ItemType[] = [];
    get getMyFriendsList(){ return this.myFriendsList }
    @Mutation
    setMyFriendsList(data: ItemType[]): void {
        this.myFriendsList = data
    }

    myFriendsNavIndex = 0;
    get getMyFriendsNavIndex(){ return this.myFriendsNavIndex }
    @Mutation
    setMyFriendsNavIndex(index: number): void {
        this.myFriendsNavIndex = index
    }

    myFriendsUpData: upDataType = {
        pageNo:1,
        pageSize:15
    };
    get getMyFriendsUpData(){ return this.myFriendsUpData }
    @Mutation
    setMyFriendsUpData(data: upDataType): void {
        this.myFriendsUpData = data
    }

    myFriendsAboutList: MyAboutItemType[] = [];
    get getMyFriendsAboutList(){ return this.myFriendsAboutList }
    @Mutation
    setMyFriendsAboutList(data: MyAboutItemType[]): void {
        this.myFriendsAboutList = data
    }


}

export const MyFriendStore = getModule(MyFriend)
